import React, { useEffect, useRef, useState } from 'react';
import './Services.css';

function Services_en() {
  const servicesRef = useRef(null);
  const [activeFilter, setActiveFilter] = useState('Information Technology Solutions');

  const services = [
    { id: 1, category: 'Software Development', title: 'Artificial Intelligence Solutions', description: 'We offer custom applications leveraging AI technologies such as deep learning and data analysis, robot development', icon: 'fas fa-robot' },
    { id: 2, category: 'Software Development', title: 'Website Development', description: 'We provide innovative design and development of websites and mobile applications', icon: 'fas fa-laptop-code' },
    { id: 3, category: 'Software Development', title: 'Mobile App Development', description: 'We offer innovative design for mobile app development', icon: 'fas fa-mobile-alt' },
    { id: 4, category: 'Information Technology Solutions', title: 'Innovative Technology Products', description: 'We offer smart devices compatible with AI software for daily work and tasks.', icon: 'fas fa-lightbulb' },
    { id: 5, category: 'Information Technology Solutions', title: 'Consulting', description: 'We help companies and individuals achieve their technical goals with modern software and tools', icon: 'fas fa-handshake' },
    { id: 6, category: 'Information Technology Solutions', title: 'Cloud Computing Services', description: 'We provide cloud computing solutions to enhance IT infrastructure.', icon: 'fas fa-cloud' },
    { id: 7, category: 'Information Technology Solutions', title: 'Security Systems', description: 'We provide advanced security systems to protect enterprises and homes from threats and risks.', icon: 'fas fa-shield-alt' },
    { id: 8, category: 'Information Technology Solutions', title: 'Infrastructure and Networks', description: 'We offer network infrastructure planning and implementation to ensure smooth operation and constant connectivity.', icon: 'fas fa-network-wired' },
    { id: 9, category: 'Information Technology Solutions', title: 'Data Analysis', description: 'We provide data analysis services to help companies make decisions based on reliable foundations.', icon: 'fas fa-chart-bar' },
    { id: 10, category: 'Marketing', title: 'Digital Marketing', description: 'Promote your products and services online using the latest means.', icon: 'fas fa-bullhorn', link: 'https://marketing.26ideas.sa/en/home/' },
    { id: 11, category: 'Enterprise Resource Planning (ERP)', title: 'Sales Management Software', description: 'These software solutions help companies improve sales processes and provide a seamless customer experience.', icon: 'fas fa-cash-register', link: 'https://store.26ideas.sa/en/category/rZWBpz/?lang=en' },
    { id: 12, category: 'Enterprise Resource Planning (ERP)', title: 'Inventory Management Software', description: 'These software solutions help companies efficiently organize warehouses.', icon: 'fas fa-warehouse', link: 'https://store.26ideas.sa/en/category/rZWBpz/?lang=en' },
    { id: 13, category: 'Enterprise Resource Planning (ERP)', title: 'Human Resources Management Systems', description: 'We provide comprehensive systems for managing HR, including payroll, attendance, and recruitment.', icon: 'fas fa-user-friends', link: 'https://store.26ideas.sa/en/category/rZWBpz/?lang=en' },
    { id: 14, category: 'Enterprise Resource Planning (ERP)', title: 'Accounting Systems', description: 'We provide accurate accounting systems for managing accounts and financial balance.', icon: 'fas fa-calculator', link: 'https://store.26ideas.sa/en/category/rZWBpz/?lang=en' },
    { id: 15, category: 'Enterprise Resource Planning (ERP)', title: 'Restaurant and Cafe Management', description: 'We offer solutions for managing restaurant and cafe operations, from reservations to billing.', icon: 'fas fa-utensils', link: 'https://store.26ideas.sa/en/category/rZWBpz/?lang=en' },
    { id: 16, category: 'Enterprise Resource Planning (ERP)', title: 'General Ledger and Cost Center Management', description: 'We provide integrated solutions for managing general ledger accounts and cost centers efficiently.', icon: 'fas fa-balance-scale', link: 'https://store.26ideas.sa/en/category/rZWBpz/?lang=en' },
    { id: 17, category: 'Enterprise Resource Planning (ERP)', title: 'Hotel Management', description: 'We offer comprehensive solutions for managing hotel operations, including reservations, billing, and logistics.', icon: 'fas fa-hotel', link: 'https://store.26ideas.sa/en/category/rZWBpz/?lang=en' },
    { id: 18, category: 'Enterprise Resource Planning (ERP)', title: 'Sports Club Management', description: 'We provide solutions for managing sports club memberships, bookings, and events.', icon: 'fas fa-dumbbell', link: 'https://store.26ideas.sa/en/category/rZWBpz/?lang=en' }
  ];

  const filteredServices = activeFilter === 'All' ? services : services.filter(service => service.category === activeFilter);
  
  const toggleFilter = (filter) => {
    setActiveFilter(filter);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            entry.target.classList.remove('hidden');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.2 }
    );

    const servicesSection = servicesRef.current;
    const children = servicesSection.querySelectorAll('.fade-in-element');

    children.forEach((child) => {
      observer.observe(child);
    });

    return () => {
      children.forEach((child) => {
        observer.unobserve(child);
      });
    };
  }, []);

  return (
    <div className="Services" id="Services" ref={servicesRef}>
      <div className='title-wrapper'>
        <h2 className='Services-Title fade-in-element hidden'>Our Services</h2>
      </div>
      <p className="Services-Description fade-in-element hidden">
        Our services are designed specifically to meet your needs
      </p>

      {/* Filter Buttons */}
      <div className="filter-buttons">
        <button className={activeFilter === 'Information Technology Solutions' ? 'active' : ''} onClick={() => toggleFilter('Information Technology Solutions')}>Information Technology Solutions</button>
        <button className={activeFilter === 'Software Development' ? 'active' : ''} onClick={() => toggleFilter('Software Development')}>Software Development</button>
        <button className={activeFilter === 'Enterprise Resource Planning (ERP)' ? 'active' : ''} onClick={() => toggleFilter('Enterprise Resource Planning (ERP)')}>ERP Systems</button>
        <button className={activeFilter === 'All' ? 'active' : ''} onClick={() => toggleFilter('All')}>All</button>
      </div>

      <div className="Services-Grid fade-in-element hidden">
        {filteredServices.map(service => (
          <a key={service.id} href={service.link || '/en/contact-us'} target={service.link ? '_blank' : ''} rel="noreferrer">
            <div className="Service-Box">
              <div className="Service-Upper">
                <i className={`${service.icon} Service-Icon`}></i>
              </div>
              <div className="Service-Lower">
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            </div>
          </a>
        ))}
      </div>

      <div className="Button-Container fade-in-element hidden">
        <a className='link' href="/services">
          <button className="Learn-More-Button">Learn More About Our Services</button>
        </a>
      </div>
    </div>
  );
}

export default Services_en;